<template>
   <div class="about d-flex justify-center align-center">
      <v-card class="pa-3 mx-5" max-width="500" elevation="5">
         <v-card-text>
            <h1 class="mb-6">{{ name }}</h1>
            <div class="text--primary">
               {{ name }} es una plataforma que permite hacer el proceso de brindar soporte a los clientes, mucho más eficiente. En {{ name }} se
               pueden crear reportes de servicio y enviarlos a los clientes para ser firmados por SMS o correo electrónico, es posible establecer
               catálogos de clientes con horas de servicio disponibles y generar consultas inteligentes para monitorear el estado del departamento de
               soporte.
            </div>
         </v-card-text>
         <v-card-actions>
            <v-btn color="primary" @click="$router.push({ name: 'Contacto' })">contáctanos</v-btn>
         </v-card-actions>
      </v-card>
   </div>
</template>

<script>
import c from "../const";

export default {
   name: "About",
   data: () => {
      return {
         name: c.APP_NAME,
      };
   },
};
</script>

<style scoped>
.about {
   height: 100%;
}
</style>
